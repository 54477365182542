import "./App.css";
import AnimatedStates from "./components/Home/HomePage";
import { BrowserRouter as Router,Routes ,Route } from "react-router-dom";
import SignUp from "./components/SignUp/SignUp";
import NavBar from "./components/NavBar/navbar";
import Pricing from "./components/Pricing/Pricing";
import { useState } from "react";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/Blog/BlogPost";

function App() {

    const [navBarProperties, setNavBarProperties] = useState({
        opacity: 1,
    });

    return (
        <div className="App">
            <Router>
                <NavBar navBarProperties={navBarProperties} />
                <Routes>
                    <Route exact path="/" element={<AnimatedStates setNavBarProperties={setNavBarProperties}/>} />
                    <Route path="/signup" element={<SignUp/>} />
                    <Route path="/pricing" element={<Pricing/>} />
                    <Route path="/blog" element={<Blog/>} />
                    <Route path="/blog/:url" element={<BlogPost/>} />
                    <Route path="*" element={<h1>404 Not Found</h1>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
