import React, { useEffect } from 'react';
import './InputField.css';

const InputField = ({ text, validationFunction, reff, externalError }) => {
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState('');
    const [exError, setExError] = React.useState('');
    useEffect(() => {
        setExError(externalError);
    }, [externalError]);

    const handleInputChange = (event) => {
        setExError('');
        const newValue = event.target.value;
        const error = validationFunction(newValue);

        setValue(newValue);
        setError(error);
    };

    return (
        <div className="field">
            <input 
                type="text" 
                placeholder={text} 
                value={value} 
                onChange={handleInputChange} 
                ref={reff}
            />

            <div className="line"></div>
            {(exError || error) && <div className="error">{exError || error}</div>}

        </div>
    );
};

export default InputField;