import React, { useEffect, useState } from 'react';
import './Pricing.css';
import MaterialUISwitch from './SwitchButton';
import cupcake from '../../assets/cupcake.gif';
import cake from '../../assets/cake.gif';
import cakeMid from '../../assets/cakeMid.gif';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
    const [mode, setMode] = useState('monthly');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currency, setCurrency] = useState('₹');
    const [isIndia, setIsIndia] = useState(true);

    useEffect(() => {
        fetch('https://api.geoapify.com/v1/ipinfo?apiKey=e38ad7dd0d2c46c99408ba94bc03e2df')
        .then(response => response.json())
        .then(data => {
            // You can now access the location data in the "data" object
            console.log(data);
            if (data.country.iso_code === 'IN') {
                setIsIndia(true);
                setCurrency('₹');
            } else {
                setIsIndia(false);
                setCurrency('$');
            }
        })
        .catch(error => console.log(error));
    }, []);

    const prices = isIndia ? {
        'monthly': [
            { plan: 'Lite', features:['3D digital menu', 'AR View', 'Customizable Menu', '50 Items'], cost: `${currency}999`, discount: '' , heading:''},
            { plan: 'Pro', features:['100 Items','Integration with POS', 'Realtime Analytics', 'User Feedback', 'Item rating'], cost: `${currency}1999`, discount: '', heading:'Everything from Lite plus:' },
            { plan: 'Enterprise', features:[ 'Unlimited Items','Swiggy, Zomato Integration', 'Call Waiter', 'Order Status', 'Virtual waiter AI'], cost: `${currency}NA`, discount: '' , heading:'Everything from Pro plus:'}
        ],
        'yearly': [
            { plan: 'Lite', features:['3D digital menu', 'AR View', 'Customizable Menu', '50 Items'], cost: `${currency}9999`, discount: '2 months free' , heading:''},
            { plan: 'Pro', features:['100 Items', 'Integration with POS', 'Realtime Analytics', 'User Feedback', 'Item rating'], cost: `${currency}19999`, discount: '2 months free' , heading:'Everything from Lite plus:'},
            { plan: 'Enterprise', features:['Unlimited Items', 'Swiggy, Zomato Integration', 'Call Waiter', 'Order Status', 'Virtual waiter AI'], cost: `${currency}NA`, discount: '2 months free' , heading:'Everything from Pro plus:'}
        ]
    } : {
        'monthly': [
            { plan: 'Lite', features:['3D digital menu', 'AR View', 'Customizable Menu', '50 Items'], cost: `${currency}24.99`, discount: '' , heading:''},
            { plan: 'Pro', features:['100 Items','Integration with POS', 'Realtime Analytics', 'User Feedback', 'Item rating'], cost: `${currency}34.99`, discount: '', heading:'Everything from Lite plus:' },
            { plan: 'Enterprise', features:[ 'Unlimited Items','Online Orders Integration', 'Call Waiter', 'Order Status', 'Virtual waiter AI'], cost: `${currency}49.99`, discount: '' , heading:'Everything from Pro plus:'}
        ],
        'yearly': [
            { plan: 'Lite', features:['3D digital menu', 'AR View', 'Customizable Menu', '50 Items'], cost: `${currency}249.99`, discount: '2 months free' , heading:''},
            { plan: 'Pro', features:['100 Items', 'Integration with POS', 'Realtime Analytics', 'User Feedback', 'Item rating'], cost: `${currency}349.99`, discount: '2 months free' , heading:'Everything from Lite plus:'},
            { plan: 'Enterprise', features:['Unlimited Items', 'Online Orders Integration', 'Call Waiter', 'Order Status', 'Virtual waiter AI'], cost: `${currency}499.99`, discount: '2 months free' , heading:'Everything from Pro plus:'}
        ]
    };

    const handleCardClick = (plan) => {
        setSelectedPlan(plan);
    };

    const navigate = useNavigate();

    const onClick = () => {
        navigate('/signup');
    }

    return (
        <div className="pricing-container">
            <div className='pricing-title'>Pricing</div>
            <div className="pricing-switch">
                <p style={{paddingInline:'1%', fontSize:'1.5rem'}}>Monthly</p>
                <MaterialUISwitch
                    checked={mode === 'yearly'}
                    onChange={() => setMode(mode === 'monthly' ? 'yearly' : 'monthly')}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <p style={{paddingInline:'1%', fontSize:'1.5rem'}}>Yearly</p>
            </div>
            <div className="pricing-cards-container">
                {prices[mode].map((price, index) => (
                    <div key={index} className={`pricing-card ${selectedPlan === price.plan ? 'selected' : ''}`} onClick={() => handleCardClick(price.plan)}>
                        <h2>{price.plan}</h2>
                        <div className="pricing-card-features">
                            <div className='pricing-heading'>{price.heading}</div>
                            {price.features.map((feature, index) => (
                                <ul key={index} style={{fontSize:'1.1rem', textAlign:'left',lineHeight:'1.3rem'}}>
                                    <li >{feature}</li>
                                </ul>
                            ))}
                        </div>
                        {/* <div className="pricing-card-image">
                            <img src={price.image} alt={price.plan} />
                        </div> */}
                        <div className="pricing-card-divider">
                            <p style={{fontSize:'1.4rem'}}>Cost : {price.cost}</p>
                            {(price.discount !== '')&& <p style={{fontSize:'1rem',fontWeight:'100'}}>{price.discount}</p>}
                        </div>
                    </div>
                ))}
            </div>
                <div className="pricing-button" onClick={onClick}> Get Started </div>
        </div>
    );
};

export default Pricing;