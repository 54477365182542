import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Blog.css';
import { blog1 } from './articles/blog1';
import { blog2 } from './articles/blog2';
import { blog3 } from './articles/blog3';
import { blog4 } from './articles/blog4';
import { blog5 } from './articles/blog5';
import { blog6 } from './articles/blog6';
import { blog7 } from './articles/blog7';
import { blog8 } from './articles/blog8';
import { blog9 } from './articles/blog9';


export const blogs = [
    {
        title: 'Digital or Print Menus: Choosing the Best',
        url: 'digital-or-print-menus-choosing-the-best',
        description: 'Choosing the Ideal Menu Format for Your Business.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/digitalvprint2.jpg',
        component: blog1
    },
    {
        title: 'Upgrade Your Menu: Best Online Solutions',
        url: 'upgrade-your-menu-best-online-solutions',
        description: 'Streamline your restaurant with the best online menu QR code solution. Find the perfect fit today!',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/onlineMenu.jpg',
        component: blog2

    },
    {
        title: 'QR Menus: Improve Sales & Satisfaction',
        url: 'qr-menus-improve-sales-and-satisfaction',
        description: 'Boost restaurant sales & customer satisfaction with QR menus. Learn how they transform dining.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/innovative3.jpeg',
        component: blog3
    },
    {
        title: 'Online Menus: The Restaurant Revolution',
        url: 'online-menus-the-restaurant-revolution',
        description: 'Explore how online menus revolutionize the restaurant experience - convenience, insights, & more.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/innovative4.jpeg',
        component: blog4
    },
    {
        title: 'How Profitable Is A Restaurant Business',
        url: 'how-profitable-is-a-restaurant-business',
        description: 'Is owning a restaurant profitable? Uncover key factors and strategies for financial success.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/b5-0.jpeg',
        component: blog5
    },
    {
        title: 'How to Create Restaurant Recipe Cards [Templates]',
        url: 'how-to-create-restaurant-recipe-cards',
        description: 'Restaurant recipe cards are a simple tool to help train new kitchen staff, minimize food waste, and make your restaurant more profitable.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/b6-0.jpg',
        component: blog6
    },
    {
        title: 'Exploring the Rich Tapestry of Chinese Cuisine: A Journey Through Flavors',
        url: 'exploring-the-rich-tapestry-of-chinese-cuisine',
        description: 'Chinese cuisine, with its rich history and diverse regional variations, is a culinary tapestry that has captivated taste buds around the globe for centuries.',
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/b7-0.jpg',
        component: blog7
    },
    {
        title: 'Crafting a Successful Restaurant Business Plan: Recipe for Success',
        url: 'crafting-a-successful-restaurant-business-plan',
        description: "Crafting a comprehensive restaurant business plan is the crucial first step towards turning your culinary dreams into a successful reality. Just like a well-prepared dish, a solid business plan requires careful consideration of key ingredients and meticulous planning. Let's delve into the essential components of a restaurant business plan and uncover the recipe for success.",
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/bx-1.jpg',
        component: blog8
    },
    {
        title: 'Embracing the Future of Dining: QR Food Menus in Restaurants',
        url: 'embracing-the-future-of-dining:-qr-food-menus-in-restaurants',
        description: "QR (Quick Response) codes have become ubiquitous in our digital landscape, offering a quick and convenient way to access information with a simple scan using a smartphone camera or a dedicated QR code scanning app.",
        image: 'https://s3.ap-south-1.amazonaws.com/global.dump/bx-2.jpg',
        component: blog9
    },
    // Add more blog posts here
];
const Blog = () => {

    const navigate = useNavigate();

    const handleClick = (url) => {
        navigate(`/blog/${url}`);
    };

    return (
        <div className="blog-container">
            <div className="blog-heading">Blogs</div>
            <div className="blogs">
                {blogs.map((blog, index) => (
                    <div key={index} className="blog" onClick={() => handleClick(blog.url)}>
                        <img src={blog.image} alt={blog.title} className="blog-image" />
                        <h2 className="blog-title">{blog.title}</h2>
                        <p className="blog-description">{blog.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;