import "./navbar.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavBar({navBarProperties}) {
    const navigator = useNavigate();
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const opacity = isHomePage ? navBarProperties.opacity : 1;

    const fillColor = isHomePage ? "#ffffff" : "#000000";
    const signupText = isHomePage ? "Sign Up" : "Home";

    const handleClickSignup = () => {
        if (isHomePage) {
            navigator("/signup");
        } else {
            navigator("/");
        }
    }

    return (
        <div className="navbar" style={{opacity: opacity}}>
            <div className="navbar__container">
                <div className="navbar__left">
                    <Logo fillColor={fillColor} width="50px" height="50px"/>               
                </div>
                <div className="navbar__right">
                    <div className="signup-button" onClick={handleClickSignup}>{signupText}</div>
                    <div className="blog-nav-button" onClick={() => navigator("/blog")}>Blog</div>
                    <div className="pricing-nav-button" onClick={() => navigator("/pricing")}>Pricing</div>
                </div>
            </div>
        </div>
    );
}

export function Logo({ fillColor, width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            preserveAspectRatio="xMidYMid meet"
            viewBox="126 183 247.8 134"
            className="navbar__logo"
            width={width}
            height={height}
            onClick={() => window.location.href = "/"}
        >
            <g
                transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
                fill={fillColor}
                stroke="none"
            >
            <path d="M1992 3103 c-133 -228 -732 -1267 -732 -1270 0 -2 69 -3 153 -3 l152 0 137 238 c374 651 480 832 486 832 4 0 74 -117 156 -260 82 -143 152 -260 156 -260 7 0 150 244 150 256 0 3 -69 125 -152 270 l-153 264 -157 0 -157 0 -39 -67z"></path>
            <path fill="orange" d="M2815 2635 c-170 -294 -312 -535 -315 -535 -3 0 -73 117 -155 260 -83 143 -153 260 -157 260 -12 0 -149 -244 -146 -260 2 -9 70 -131 152 -273 l149 -257 157 0 158 0 238 413 c130 226 303 525 382 662 79 138 146 253 149 258 2 4 -65 7 -150 7 l-154 0 -308 -535z"></path>
            <path d="M3202 2494 c-54 -93 -72 -132 -65 -144 4 -10 73 -131 153 -269 l145 -251 153 0 c85 0 152 4 150 8 -13 32 -451 782 -457 782 -3 0 -39 -57 -79 -126z"></path>
            </g>
        </svg>
    );
}