import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { blogs } from './Blog';
import './BlogPost.css';

const BlogPost = () => {
    const { url } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const blogPost = blogs.find((blog) => blog.url === url);
        if (blogPost) {
            setBlog(blogPost.component);
        } else {
            setBlog(<div>Sorry, the blog post you are looking for does not exist.</div>);
        }
    }, [url]);

    return (
        <div className="blog-post">
            {blog}
        </div>
    );
};

export default BlogPost;