import './SubmitButton.css';

import React, { useState } from 'react';

const SubmitButton = (onClick) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const onClickFunction = onClick.onClick;

  const tickMark = (
    <svg width="29" height="22.5" viewBox="0 0 58 45" xmlns="http://www.w3.org/2000/svg">
      <path fill="#fff" fillRule="nonzero" d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65" />
    </svg>
  );

  const handleClick = async () => {
    if (isClicked) return;
    const isValid = await onClickFunction();
    if (!isValid) return;
    setIsClicked(true);
    setIsSubmitted(!isSubmitted);
  };

  return (
    <div className={`button ${isSubmitted ? 'button__circle' : ''}`} onClick={handleClick}>
      <div className="container">
        <div className="tick">{isSubmitted ? tickMark : 'Submit'}</div>
      </div>
    </div>
  );
};

export default SubmitButton;
