import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import "./HomePage.css";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { MathUtils } from "three";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { useLoader } from "@react-three/fiber";
import { useMediaQuery } from "react-responsive";
import { Logo } from "../NavBar/navbar";
import createScrollSnap from "scroll-snap";
import { useNavigate } from "react-router";
import QRCode from "qrcode.react";

const ar = "https://s3.ap-south-1.amazonaws.com/global.dump/ar.gif";
const pos = "https://s3.ap-south-1.amazonaws.com/global.dump/pos.gif";
const virtualWaiter =
    "https://s3.ap-south-1.amazonaws.com/global.dump/virtual_waiter.jpg";
const customize =
    "https://s3.ap-south-1.amazonaws.com/global.dump/customize.gif";

// Define URLs for 3D models
const gltfUrl =
    "https://s3.ap-south-1.amazonaws.com/global.dump/english-breakfast.glb";
const tableUrl = "https://s3.ap-south-1.amazonaws.com/global.dump/table.glb";
const phoneUrl = "https://s3.ap-south-1.amazonaws.com/global.dump/phone.glb";

// Constants for camera and animation thresholds
let CAMERA_Z_MULTIPLIER = 0.5;
let CAMERA_Y_MULTIPLIER = 0.8;
let NUMBER_OF_STATES = 7;
let STATE_SCROLL_SPAN = 1 / (NUMBER_OF_STATES - 1);
let BUFFER = 0.001;
let ANIMATION_THRESHOLD_1 = STATE_SCROLL_SPAN;
let ANIMATION_THRESHOLD_2 = STATE_SCROLL_SPAN * 2;
let ANIMATION_THRESHOLD_3 = STATE_SCROLL_SPAN * 3;
let ANIMATION_THRESHOLD_4 = STATE_SCROLL_SPAN * 4;
let ANIMATION_THRESHOLD_5 = STATE_SCROLL_SPAN * 5;

const AnimatedStates = ({ setNavBarProperties }) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isPortrait = useMediaQuery({ orientation: "portrait" });
    const [foodGltf, setFoodGltf] = useState(null);
    const [tableGltf, setTableGltf] = useState(null);
    const [phoneGltf, setPhoneGltf] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [gifActive, setGifActive] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);

    const [lineY, setLineY] = useState(0);

    useEffect(() => {
        setLoadingProgress(0);
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(
            "https://unpkg.com/three@0.153.0/examples/jsm/libs/draco/gltf/"
        );
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);
        const loadModels = async () => {
            const totalModels = 3;
            let loadedModels = 0;

            const foodGltf = await new Promise((resolve, reject) => {
                gltfLoader.load(
                    gltfUrl,
                    resolve,
                    // onProgress callback
                    (xhr) => {
                        const progress =
                            ((xhr.loaded / xhr.total) * 100) / totalModels +
                            (100 / totalModels) * loadedModels;
                        setLoadingProgress(progress);
                    },
                    reject
                );
            });
            loadedModels++;
            setFoodGltf(foodGltf);

            const tableGltf = await new Promise((resolve, reject) => {
                gltfLoader.load(
                    tableUrl,
                    resolve,
                    // onProgress callback
                    (xhr) => {
                        const progress =
                            ((xhr.loaded / xhr.total) * 100) / totalModels +
                            (100 / totalModels) * loadedModels;
                        setLoadingProgress(progress);
                    },
                    reject
                );
            });
            loadedModels++;
            setTableGltf(tableGltf);

            const phoneGltf = await new Promise((resolve, reject) => {
                gltfLoader.load(
                    phoneUrl,
                    resolve,
                    // onProgress callback
                    (xhr) => {
                        const progress =
                            ((xhr.loaded / xhr.total) * 100) / totalModels +
                            (100 / totalModels) * loadedModels;
                        setLoadingProgress(progress);
                    },
                    reject
                );
            });
            loadedModels++;
            setPhoneGltf(phoneGltf);

            setIsLoading(false);
        };

        loadModels();
    }, []);

    const statesContainerRef = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const foodModelRef = useRef();
    const canvasContainerRef = useRef();

    const light1Ref = useRef();
    const light2Ref = useRef();
    const light3Ref = useRef();

    const mousePosition = useMousePosition();

    let taglineInput, cameraPositions;

    if (isDesktopOrLaptop) {
        taglineInput = {
            text: "Transforming Menus, Elevating Dining",
            amount: 400,
            particleSize: 0.2,
            particleColor: 0xffffff,
            textSize: 1,
            area: 5,
            ease: 0.4,
            position: { x: 0, y: 5, z: 0 },
            yStart: 0,
            y1: 0,
            y2: 0,
        };
        cameraPositions = [new THREE.Vector3(0, 0, 2)];
        NUMBER_OF_STATES = 5;
        STATE_SCROLL_SPAN = 1 / (NUMBER_OF_STATES - 1);
        ANIMATION_THRESHOLD_1 = STATE_SCROLL_SPAN;
        ANIMATION_THRESHOLD_2 = STATE_SCROLL_SPAN * 2;
        ANIMATION_THRESHOLD_3 = STATE_SCROLL_SPAN * 3 + BUFFER;
        ANIMATION_THRESHOLD_4 = STATE_SCROLL_SPAN * 4;
        ANIMATION_THRESHOLD_5 = STATE_SCROLL_SPAN * 5;
    } else if (isTabletOrMobile) {
        if (isPortrait) {
            taglineInput = {
                text: "Transforming Menus,\n Elevating Dining",
                amount: 300,
                particleSize: 0.3,
                particleColor: 0xffffff,
                textSize: 0.8,
                area: 5,
                ease: 0.4,
                position: { x: 0, y: 0, z: 0 },
                yStart: 0,
                y1: 4,
                y2: 0,
            };
            cameraPositions = [new THREE.Vector3(0, 0, 2.8)];
        } else {
            taglineInput = {
                text: "Transforming Menus, Elevating Dining",
                amount: 400,
                particleSize: 0.2,
                particleColor: 0xffffff,
                textSize: 1,
                area: 5,
                ease: 0.4,
                position: { x: 0, y: 0, z: 0 },
                yStart: 0,
                y1: 0,
                y2: 0,
            };
            cameraPositions = [new THREE.Vector3(0, 0, 2)];
        }
    }

    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        if (isLoading) {
            statesContainerRef.current.style.overflowY = "hidden";
        } else {
            statesContainerRef.current.style.overflowY = "auto";
        }
    }, [isLoading, statesContainerRef]);

    useEffect(() => {
        const element = statesContainerRef.current;

        if (element) {
            const { bind, unbind } = createScrollSnap(element, {
                snapDestinationX: "0%",
                snapDestinationY: "100%",
                timeout: 1,
                duration: 1000,
                threshold: 0.01,
                snapStop: false,
                easing: function (t) {
                    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                }, // easeInOutQuad
            });

            const setProgress = () => {
                const scrollTop = element.scrollTop;
                const scrollHeight =
                    element.scrollHeight - element.clientHeight;
                const progress = scrollTop / scrollHeight;
                setScrollProgress(progress);
            };

            element.addEventListener("scroll", setProgress);

            if (!isTabletOrMobile) {
                bind();
                element.style.scrollSnapType = "none";
                element.style.scrollSnapStop = "none";
                element.style.scrollBehavior = "none";
            } else {
                element.style.scrollSnapType = "y mandatory";
                element.style.scrollSnapStop = "always";
                element.style.scrollBehavior = "smooth";
            }
        }
    }, [statesContainerRef]);

    useEffect(() => {
        const navBarVisibleThreshold = 0.1;
        const navBarHiddenThreshold = 0.05;
        // lerp between 0 and 1
        const opacity = MathUtils.lerp(
            0,
            1,
            (scrollProgress - navBarHiddenThreshold) /
                (navBarVisibleThreshold - navBarHiddenThreshold)
        );
        setNavBarProperties({ opacity });
        if (scrollProgress > 1 - BUFFER) {
            setGifActive(true);
        } else {
            setGifActive(false);
        }
    }, [scrollProgress]);

    return (
        <div className="states-container" ref={statesContainerRef}>
            {isLoading ? (
                <>
                    <span className="loader">
                    </span>
                    <div className="loader-text">
                        {Math.floor(loadingProgress)}%
                    </div>
                </>
            ) : null}
            <div className="canvas-container" ref={canvasContainerRef}>
                <Canvas>
                    <ParticlesTagLine
                        progress={scrollProgress}
                        dataInput={taglineInput}
                        isTabletOrMobile={isTabletOrMobile}
                    />
                    <ambientLight ref={light1Ref} intensity={1} />
                    <directionalLight
                        ref={light2Ref}
                        position={[0, 0, 10]}
                        intensity={1.5}
                    />
                    <directionalLight
                        ref={light3Ref}
                        position={[0, 100, 0]}
                        intensity={1.5}
                    />
                    <PerspectiveCamera makeDefault position={[0, 0, 2.8]} />
                    {isLoading ? (
                        <></>
                    ) : (
                        <>
                            {isTabletOrMobile ? (
                                <RotatingCubePhone
                                    progress={scrollProgress}
                                    mousePosition={mousePosition}
                                    cameraPositions={cameraPositions}
                                    light1Ref={light1Ref}
                                    light2Ref={light2Ref}
                                    light3Ref={light3Ref}
                                    foodGltf={foodGltf}
                                    tableGltf={tableGltf}
                                    phoneGltf={phoneGltf}
                                    foodModelRef={foodModelRef}
                                    setLineY={setLineY}
                                />
                            ) : (
                                <RotatingCube
                                    progress={scrollProgress}
                                    mousePosition={mousePosition}
                                    cameraPositions={cameraPositions}
                                    light1Ref={light1Ref}
                                    light2Ref={light2Ref}
                                    light3Ref={light3Ref}
                                    foodGltf={foodGltf}
                                    tableGltf={tableGltf}
                                    phoneGltf={phoneGltf}
                                    foodModelRef={foodModelRef}
                                />
                            )}
                        </>
                    )}
                </Canvas>
            </div>
            <State1
                ref={ref1}
                scrollProgress={scrollProgress}
                isLoading={isLoading}
            />
            <State2 ref={ref2} />
            {isTabletOrMobile ? (
                <>
                    <State3
                        ref={ref3}
                        scrollProgress={scrollProgress}
                        lineY={lineY}
                    />
                    <State4
                        ref={ref4}
                        scrollProgress={scrollProgress}
                        lineY={lineY}
                    />
                    <State5 ref={ref5} scrollProgress={scrollProgress} />
                    <State6 />
                    <State7 />
                </>
            ) : (
                <>
                    <State3Desktop
                        ref={ref3}
                        scrollProgress={scrollProgress}
                        lineY={lineY}
                    />
                    <State4Desktop
                        ref={ref4}
                        scrollProgress={scrollProgress}
                        lineY={lineY}
                    />
                    <State5Desktop ref={ref5} scrollProgress={scrollProgress} />
                    {gifActive && (
                        <>
                            <State6Desktop />
                            <State7Desktop />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

function State1({ scrollProgress, isLoading }) {
    const active = scrollProgress <= 0.1;

    return (
        <div className="state1-container">
            {active && (
                <SignupModal
                    scrollProgress={scrollProgress}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
}

function ScrollHint() {
    const [isClicked, setIsClicked] = useState(false);

    const handleArrowClick = () => {
        setIsClicked(true);
        const scrollElement = document.querySelector(".states-container");
        scrollElement.scrollTo({
            top: scrollElement.scrollTop + 100, // Current scroll position plus the desired scroll amount
            behavior: "smooth",
        });
    };

    return (
        <svg
            className="arrows"
            onClick={handleArrowClick}
            style={{ pointerEvents: "auto" }}
        >
            <path
                className="a1"
                d="M0 0 L15 16 L30 0"
                style={isClicked ? { stroke: "orange" } : {}}
            ></path>
            <path
                className="a2"
                d="M0 10 L15 26 L30 10"
                style={isClicked ? { stroke: "orange" } : {}}
            ></path>
            <path
                className="a3"
                d="M0 20 L15 36 L30 20"
                style={isClicked ? { stroke: "orange" } : {}}
            ></path>
        </svg>
    );
}

function SignupModal({ scrollProgress, isLoading }) {
    const navigate = useNavigate();
    const active = scrollProgress <= 0.1;
    const modalClass = active
        ? "signup-modal-body show"
        : "signup-modal-body hide";

    const handleClickSignup = () => {
        navigate("/signup");
    };
    // const isMobile = window.innerWidth <= 500;
    const isMobile = true;

    return (
        <div
            className="signup-modal-container"
            style={{ pointerEvents: active ? "none" : "auto" }} // change here
        >
            {active && (
                <div className={modalClass}>
                    <div>
                        <div
                            className="logo-container"
                            style={{
                                opacity: 1 - scrollProgress * 10,
                                transform: `scale(${1 - scrollProgress})`,
                            }}
                        >
                            <Logo
                                fillColor="#ffffff"
                                width="150px"
                                height="auto"
                            />
                        </div>
                        <div
                            className="title-container"
                            style={{
                                opacity: 1 - scrollProgress * 10,
                                transform: `scale(${1 - scrollProgress})`,
                            }}
                        >
                            MENU VISION
                        </div>
                    </div>
                    {!isMobile && <MobileRedirect />}
                    {isMobile && (
                        <>
                            <div className="buttons-container">
                                <div
                                    className="modal-button"
                                    style={{
                                        transform: `translateX(-${
                                            scrollProgress * 5000
                                        }px)`,
                                        opacity: 1 - scrollProgress * 40,
                                        pointerEvents: "auto",
                                        border: "3px solid white",
                                        position: "relative",
                                        overflow: "hidden",
                                        zIndex: 100000000000,
                                    }}
                                    onClick={handleClickSignup}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    ></div>
                                    Contact Us
                                </div>
                                <a
                                    href="https://demo.menuvision.in"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <div
                                        className="modal-button"
                                        style={{
                                            transform: `translateX(${
                                                scrollProgress * 5000
                                            }px)`,
                                            opacity: 1 - scrollProgress * 40,
                                            pointerEvents: "auto",
                                            border: "3px solid white",
                                            position: "relative",
                                            overflow: "hidden",
                                            zIndex: 100000000000,
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        ></div>{" "}
                                        Demo{" "}
                                    </div>
                                </a>
                                {!isLoading && <ScrollHint />}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

function MobileRedirect() {
    return (
        <div className="redirect-container">
            <div className="redirect-message">
                Scan the QR code or access "www.menuvision.in" on your mobile
                device
            </div>
            <div className="qr-code">
                <QRCode value={"https://www.menuvision.in"} />
            </div>
        </div>
    );
}

function State2() {
    return (
        <div className="state2-container">
            <div className="state2-body"></div>
        </div>
    );
}

function State3({ scrollProgress, lineY }) {
    const titleRef = useRef();
    const buffer = 0.01;
    const active = scrollProgress > ANIMATION_THRESHOLD_2 - buffer;
    const triggerExit = scrollProgress > ANIMATION_THRESHOLD_2 + 2 * buffer;

    let normalizedProgress =
        (scrollProgress - ANIMATION_THRESHOLD_1) /
        (ANIMATION_THRESHOLD_2 - ANIMATION_THRESHOLD_1);
    if (normalizedProgress > 1) {
        normalizedProgress = 1;
    }

    let titleHeight = 0;
    if (titleRef.current) {
        titleHeight = titleRef.current.clientHeight;
    }

    return (
        <div className="state3-container">
            {active && (
                <div
                    className="state3-body"
                    style={{ pointerEvents: active ? "none" : "auto" }}
                >
                    <div
                        className="animated-title"
                        style={{ top: `${lineY - titleHeight / 2}px` }}
                        ref={titleRef}
                    >
                        <div className="text-top">
                            <div className={`${triggerExit ? "exit" : ""}`}>
                                <span>Ordinary Menus</span>
                            </div>
                        </div>
                        <div className="text-bottom">
                            <div className={`${triggerExit ? "exit" : ""}`}>
                                Holding you back?
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function State4({ scrollProgress, lineY }) {
    const titleRef = useRef();
    const buffer = 0.01;
    const active = scrollProgress > ANIMATION_THRESHOLD_3 - buffer;
    const triggerExit = scrollProgress > ANIMATION_THRESHOLD_3 + 2 * buffer;

    let titleHeight = 0;
    if (titleRef.current) {
        titleHeight = titleRef.current.clientHeight;
    }

    return (
        <div className="state3-container">
            {active && (
                <div
                    className="state3-body"
                    style={{ pointerEvents: active ? "none" : "auto" }}
                >
                    <div
                        className="animated-title"
                        style={{ top: `${lineY - titleHeight / 2}px` }}
                        ref={titleRef}
                    >
                        <div className="text-top2">
                            <div className={`${triggerExit ? "exit2" : ""}`}>
                                <span>Visualize</span>
                                <span>Your Menu</span>
                            </div>
                        </div>
                        <div className="text-bottom">
                            <div className={`${triggerExit ? "exit2" : ""}`}>
                                In Stunning 3D!
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function State5({ scrollProgress }) {
    const buffer = 0.01;
    const ref = useRef();
    const active = scrollProgress > ANIMATION_THRESHOLD_4 - buffer;

    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const containerStyle = isIOS()
        ? {
              position: "relative",
              minHeight: "100vh",
              height: "100vh",
              width: "100%",
              zIndex: 1000,
          }
        : {};

    useEffect(() => {
        if (!ref.current) return;
        var words = document.getElementsByClassName("word");
        var wordArray = [];
        var currentWord = 0;

        words[currentWord].style.opacity = 1;
        for (var i = 0; i < words.length; i++) {
            splitLetters(words[i]);
        }

        function changeWord() {
            if (!ref.current || !active) return;
            var cw = wordArray[currentWord];
            var nw =
                currentWord == words.length - 1
                    ? wordArray[0]
                    : wordArray[currentWord + 1];
            for (var i = 0; i < cw.length; i++) {
                animateLetterOut(cw, i);
            }

            for (var i = 0; i < nw.length; i++) {
                nw[i].className = "letter behind";
                nw[0].parentElement.style.opacity = 1;
                animateLetterIn(nw, i);
            }

            currentWord =
                currentWord == wordArray.length - 1 ? 0 : currentWord + 1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(function () {
                cw[i].className = "letter out";
            }, i * 80);
        }

        function animateLetterIn(nw, i) {
            setTimeout(function () {
                nw[i].className = "letter in";
            }, 340 + i * 80);
        }

        function splitLetters(word) {
            var content = word.innerHTML;
            word.innerHTML = "";
            var letters = [];
            for (var i = 0; i < content.length; i++) {
                var letter = document.createElement("span");
                if (content.charAt(i) === " ") {
                    letter.innerHTML = "&nbsp;"; // non-breaking space
                } else {
                    letter.className = "letter";
                    letter.innerHTML = content.charAt(i);
                }
                word.appendChild(letter);
                letters.push(letter);
            }
            wordArray.push(letters);
        }
        changeWord();
        setInterval(changeWord, 2000);
    }, [ref.current]);

    return (
        <div className="state5-container" style={containerStyle}>
            {active && (
                <div
                    className="state5-body"
                    style={{ pointerEvents: active ? "none" : "auto" }}
                >
                    <div className="text" ref={ref}>
                        <p
                            className="phrase-part-1"
                            style={{ display: "inline" }}
                        >
                            A menu that is{" "}
                        </p>
                        &nbsp;
                        <p style={{ display: "inline" }}>
                            <span className="word">unmatched.</span>
                            <span className="word">uniquely yours.</span>
                            <span className="word">3D.</span>
                            <span className="word">beautiful.</span>
                            <span className="word">immersive.</span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

function State6() {
    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const iosStyle = isIOS()
        ? {
              backgroundColor: "#000",
              color: "#ffffff",
              textAlign: "left",
              maxHeight: "95%",
              width: "80%",
              justifyContent: "center",
          }
        : {};

    const imgStyle = isIOS()
        ? {
              borderRadius: "5px",
              width: "100%",
              height: "21%",
              objectFit: "cover",
              objectPosition: "center",
              marginBottom: "2%",
          }
        : {};

    const containerStyle = isIOS()
        ? {
              position: "relative",
              minHeight: "100vh",
              height: "100vh",
              width: "100%",
              zIndex: 1000,
              backgroundColor: "#000",
          }
        : {};

    const headingStyle = isIOS()
        ? {
              height: "5%",
              fontSize: "1.5rem",
              fontWeight: 500,
              color: "#fff",
              marginBottom: "2%",
          }
        : {};

    const contentStyle = isIOS()
        ? {
              height: "auto",
              fontWeight: 100,
              fontFamily: "Poppins",
              color: "#fff",
              marginBottom: "5%",
              lineHeight: "1.5",
          }
        : {};

    const bodyStyle = isIOS()
        ? {
              position: "relative",
              top: "10%",
              minHeight: "90vh",
              height: "90%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              textAlign: "center",
              fontFamily: "Poppins",
              alignItems: "center",
              fontWeight: 300,
          }
        : {};

    return (
        <div className="state8-container" style={containerStyle}>
            <div className="state8-body" style={bodyStyle}>
                <div className={`honkai-star-rail`} style={iosStyle}>
                    <div className="segment-heading" style={headingStyle}>
                        Vision <span style={{ color: "orange" }}>AR</span>
                    </div>
                    <div className="segment-content" style={contentStyle}>
                        Say goodbye to boring menus! Experience food on your
                        table before it gets served with Augmented Reality.
                    </div>
                    <img
                        className="custom-img"
                        src={ar}
                        alt="Logo"
                        style={imgStyle}
                    />
                    <div className="segment-heading" style={headingStyle}>
                        Vision <span style={{ color: "orange" }}>POS</span>
                    </div>
                    <div className="segment-content" style={contentStyle}>
                        Drive success with our Smart POS System. Effortlessly
                        handle bills and access in-depth restaurant analytics.
                    </div>
                    <img
                        className="custom-img"
                        src={pos}
                        alt="Logo"
                        style={imgStyle}
                    />
                </div>
            </div>
        </div>
    );
}
function State7() {
    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const iosStyle = isIOS()
        ? {
              backgroundColor: "#000",
              color: "#ffffff",
              textAlign: "left",
              maxHeight: "95%",
              width: "80%",
              justifyContent: "center",
          }
        : {};

    const imgStyle = isIOS()
        ? {
              borderRadius: "5px",
              width: "100%",
              height: "21%",
              objectFit: "cover",
              objectPosition: "center",
              marginBottom: "2%",
          }
        : {};

    const containerStyle = isIOS()
        ? {
              position: "relative",
              minHeight: "100vh",
              height: "100vh",
              width: "100%",
              zIndex: 1000,
              backgroundColor: "#000",
          }
        : {};

    const headingStyle = isIOS()
        ? {
              height: "5%",
              fontSize: "1.5rem",
              fontWeight: 500,
              color: "#fff",
              marginBottom: "2%",
          }
        : {};

    const contentStyle = isIOS()
        ? {
              height: "auto",
              fontWeight: 100,
              fontFamily: "Poppins",
              color: "#fff",
              marginBottom: "5%",
              lineHeight: "1.5",
          }
        : {};

    const footerStyle = isIOS()
        ? {
              position: "absolute",
              width: "100%",
              bottom: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "5%",
              color: "#fff",
          }
        : {};

    const bodyStyle = isIOS()
        ? {
              position: "relative",
              top: "10%",
              minHeight: "90vh",
              height: "90%",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              textAlign: "center",
              fontFamily: "Poppins",
              alignItems: "center",
              fontWeight: 300,
          }
        : {};

    return (
        <div className="state6-container" style={containerStyle}>
            <div className="state6-body" style={bodyStyle}>
                <div className={`honkai-star-rail`} style={iosStyle}>
                    <div className="segment-heading" style={headingStyle}>
                        Custom<span style={{ color: "orange" }}> Menus </span>
                    </div>
                    <div className="segment-content" style={contentStyle}>
                        Tailor your menu to perfection, with a variety of
                        templates to match your desired theme.
                        {/* {isIOS() ? <div className="segment-content">*Currently not supported on iOS devices</div> : null} */}
                    </div>
                    <img
                        className="custom-img"
                        src={customize}
                        alt="Logo"
                        style={imgStyle}
                    />
                    <div className="segment-heading" style={headingStyle}>
                        Virtual <span style={{ color: "orange" }}>Waiter</span>
                    </div>
                    <div className="segment-content" style={contentStyle}>
                        An AI powered waiter to engage your customers. Coming
                        Soon!
                    </div>
                    <img
                        className="custom-img"
                        src={virtualWaiter}
                        alt="Logo"
                        style={imgStyle}
                    />
                </div>
                <div className="footer-copyright" style={footerStyle}>
                    <p>© 2023 MenuVision Inc</p>
                </div>
            </div>
        </div>
    );
}

function State3Desktop({ scrollProgress, lineY }) {
    const buffer = 0.01;
    const active =
        scrollProgress > ANIMATION_THRESHOLD_2 - buffer &&
        scrollProgress < ANIMATION_THRESHOLD_3 - buffer;

    let normalizedProgress =
        (scrollProgress - ANIMATION_THRESHOLD_1) /
        (ANIMATION_THRESHOLD_2 - ANIMATION_THRESHOLD_1);
    if (normalizedProgress > 1) {
        normalizedProgress = 1;
    }

    // Calculate translation based on scrollProgress
    const translateX = active ? (1 - normalizedProgress) * 100 : 100;

    return (
        <div className="state3-container">
            {active && (
                <div
                    className="state3-body"
                    style={{ pointerEvents: active ? "none" : "auto" }}
                >
                    <div className="animated-title">
                        <div className="text-top">
                            <div>
                                <span>Ordinary Menus</span>
                            </div>
                        </div>
                        <div className="text-bottom">
                            <div>Holding You Back?</div>
                        </div>
                    </div>
                    <div
                        className="state3-text-container"
                        style={{
                            transform: `translateX(${translateX}%)`,
                            transition: "transform 0.3s ease-out",
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
}

function State4Desktop({ scrollProgress, lineY }) {
    const buffer = 0.01;
    const active =
        scrollProgress > ANIMATION_THRESHOLD_3 - buffer &&
        scrollProgress < ANIMATION_THRESHOLD_4 - buffer;

    let normalizedProgress =
        (scrollProgress - ANIMATION_THRESHOLD_1) /
        (ANIMATION_THRESHOLD_2 - ANIMATION_THRESHOLD_1);
    if (normalizedProgress > 1) {
        normalizedProgress = 1;
    }

    // Calculate translation based on scrollProgress
    const translateX = active ? (1 - normalizedProgress) * 100 : 100;

    return (
        <div className="state4-container">
            {active && (
                <div
                    className="state4-body"
                    style={{
                        pointerEvents: active ? "none" : "auto",
                        animation: "slide-in 0.3s forwards",
                        transform: `translateX(100%)`,
                    }}
                >
                    <div className="animated-title">
                        <div className="text-top">
                            <div>
                                <span>Visualize</span>
                                <span>Your Menus</span>
                            </div>
                        </div>
                        <div className="text-bottom">
                            <div>In Stunning 3D!</div>
                        </div>
                    </div>
                    <div
                        className="state4-text-container"
                        style={{
                            transform: `translateX(${translateX}%)`,
                            transition: "transform 0.3s ease-out",
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
}

function State5Desktop({ scrollProgress }) {
    const buffer = 0.01;
    const ref = useRef();
    const active = scrollProgress > ANIMATION_THRESHOLD_4 - buffer;

    const isIOS = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    };

    const containerStyle = isIOS()
        ? {
              position: "relative",
              minHeight: "100vh",
              height: "100vh",
              width: "100%",
              zIndex: 1000,
          }
        : {};

    useEffect(() => {
        if (!ref.current) return;
        var words = document.getElementsByClassName("word");
        var wordArray = [];
        var currentWord = 0;

        words[currentWord].style.opacity = 1;
        for (var i = 0; i < words.length; i++) {
            splitLetters(words[i]);
        }

        function changeWord() {
            if (!ref.current || !active) return;

            var cw = wordArray[currentWord];
            var nw =
                currentWord == words.length - 1
                    ? wordArray[0]
                    : wordArray[currentWord + 1];

            // Check if nw is undefined
            if (typeof nw === "undefined") {
                return;
            }

            for (var i = 0; i < cw.length; i++) {
                animateLetterOut(cw, i);
            }

            for (var i = 0; i < nw.length; i++) {
                nw[i].className = "letter behind";
                nw[0].parentElement.style.opacity = 1;
                animateLetterIn(nw, i);
            }

            currentWord =
                currentWord == wordArray.length - 1 ? 0 : currentWord + 1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(function () {
                cw[i].className = "letter out";
            }, i * 80);
        }

        function animateLetterIn(nw, i) {
            setTimeout(function () {
                nw[i].className = "letter in";
            }, 340 + i * 80);
        }

        function splitLetters(word) {
            var content = word.innerHTML;
            word.innerHTML = "";
            var letters = [];
            for (var i = 0; i < content.length; i++) {
                var letter = document.createElement("span");
                if (content.charAt(i) === " ") {
                    letter.innerHTML = "&nbsp;"; // non-breaking space
                } else {
                    letter.className = "letter";
                    letter.innerHTML = content.charAt(i);
                }
                word.appendChild(letter);
                letters.push(letter);
            }
            wordArray.push(letters);
        }

        changeWord();
        setInterval(changeWord, 2000);
    }, [ref.current]);

    return (
        <div className="state5-container" style={containerStyle}>
            {active && (
                <div className="text" ref={ref}>
                    <p className="phrase-part-1" style={{ display: "inline" }}>
                        A menu that is{" "}
                    </p>
                    &nbsp;
                    <p style={{ display: "inline" }}>
                        <span className="word">unmatched.</span>
                        <span className="word">uniquely yours.</span>
                        <span className="word">3D.</span>
                        <span className="word">beautiful.</span>
                        <span className="word">immersive.</span>
                    </p>
                </div>
            )}
        </div>
    );
}

function State6Desktop() {
    return (
        <div className="state6Desktop-container">
            <div
                className="state6Desktop-body"
                style={{ animation: "bounce-in .6s" }}
            >
                <div
                    className={`honkai-star-rail`}
                    style={{ backgroundColor: "transparent" }}
                >
                    <div className="segment-heading">
                        Vision <span style={{ color: "orange" }}>AR</span>
                    </div>
                    <div className="segment-content">
                        Say goodbye to boring menus! Experience food on your
                        table before it gets served with Augmented Reality.
                    </div>
                    <img className="custom-img" src={ar} alt="Logo" />
                    <div className="divider"></div>
                    <div className="segment-heading">
                        Vision <span style={{ color: "orange" }}>POS</span>
                    </div>
                    <div className="segment-content">
                        Drive success with our Smart POS System. Effortlessly
                        handle bills and access in-depth restaurant analytics.
                    </div>
                    <img className="custom-img" src={pos} alt="Logo" />
                </div>
            </div>
        </div>
    );
}
function State7Desktop() {
    return (
        <div className="state7Desktop-container">
            <div
                className="state7Desktop-body"
                style={{ animation: "bounce-in .6s" }}
            >
                <div
                    className={`honkai-star-rail`}
                    style={{ backgroundColor: "transparent" }}
                >
                    <div className="segment-heading">
                        Custom<span style={{ color: "orange" }}> Menus </span>
                    </div>
                    <div className="segment-content">
                        Tailor your menu to perfection, with a variety of
                        templates to match your desired theme.
                        {/* {isIOS() ? <div className="segment-content">*Currently not supported on iOS devices</div> : null} */}
                    </div>
                    <img className="custom-img" src={customize} alt="Logo" />
                    <div className="divider"></div>
                    <div className="segment-heading">
                        Virtual <span style={{ color: "orange" }}>Waiter</span>
                    </div>
                    <div className="segment-content">
                        An AI powered waiter to engage your customers. Coming
                        Soon!
                    </div>
                    <img
                        className="custom-img"
                        src={virtualWaiter}
                        alt="Logo"
                    />
                </div>
            </div>
        </div>
    );
}

// Table component
export function Table(props) {
    const { scene } = props.tableGltf;
    scene.position.set(0, 0, 0.09);
    scene.rotation.x = Math.PI / 2;
    const scale = 1.5;
    scene.scale.set(scale, scale, scale);
    // set the render order to 1 so that it renders after the plate
    scene.renderOrder = 1;
    return <primitive object={scene} />;
}

// Model component
export function Model(props) {
    const { scene } = props.foodGltf;
    const { foodModelRef } = props;
    const geometry = scene.children[0].geometry;
    const material = scene.children[0].material;

    useFrame(() => {
        if (foodModelRef.current && props.rotationZ) {
            // foodModelRef.current.rotation.z = props.rotationZ * (Math.PI / 180);
            // foodModelRef.current.rotation.x = -props.rotationZ * (Math.PI / 180);
        }
        if (foodModelRef.current && props.autoRotate) {
            foodModelRef.current.rotation.y += 0.01;
        }
        if (foodModelRef.current && props.rotationX) {
            foodModelRef.current.rotation.x = props.rotationX * (Math.PI / 180);
        }
    });

    return (
        <group dispose={null} ref={foodModelRef}>
            <mesh
                castShadow
                receiveShadow
                geometry={geometry}
                material={material}
                // geometry={nodes.mesh_0.geometry}
                // material={materials["Material_0.002"]}
                position={[-0.4, -0.12, -0.4]}
                scale={0.00005}
            />
        </group>
    );
}

export function Phone({ progress, phoneGltf, foodModelRef }) {
    const { scene } = phoneGltf;
    const { camera, gl } = useThree();

    useEffect(() => {
        // make the meshes inside the scene not frustum culled
        scene.traverse((child) => {
            if (child.isMesh) {
                child.frustumCulled = false;
            }
        });
        gl.compile(scene, camera);
        const scale = 10;
        scene.scale.set(scale, scale, scale);
        scene.rotation.y = Math.PI;
    }, [scene, camera, gl]);

    return (
        <group>
            <primitive object={scene} />
        </group>
    );
}

// RotatingCube component
const RotatingCube = ({
    progress,
    cameraPositions,
    light2Ref,
    foodGltf,
    tableGltf,
    phoneGltf,
    foodModelRef,
}) => {
    const { camera } = useThree();
    const tableRef = useRef();
    const plateRef = useRef();
    const phoneRef = useRef();
    const groupRef = useRef();

    let phoneVisible = false;
    const cameraPositionZ = cameraPositions[0].z;

    let platePosition = null;
    let tablePosition = null;
    let autoRotate = false;
    let phonePosition = null;
    let phoneRotation = null;
    let plateScale = 1;
    let plateVisible = true;

    let rotation = null;
    const modelProps = {
        autoRotate: false,
        foodGltf,
        foodModelRef,
    };

    // top(landing) to state 1(tag line visible)
    if (progress <= ANIMATION_THRESHOLD_1) {
        const normalizedProgress = progress / ANIMATION_THRESHOLD_1;
        const angleInDegrees =
            -50 * MathUtils.lerp(0, 0.25, normalizedProgress) * 5;
        const angleInRadians = angleInDegrees * (Math.PI / 180);
        rotation = [angleInRadians, 0, 0];
        platePosition = [0, 0, 0.7];
        tablePosition = [0, 0, 0];
        modelProps.autoRotate = false;
        phoneVisible = false;
        phonePosition = [-10, -10, 0];
        camera.position.z =
            cameraPositionZ +
            CAMERA_Z_MULTIPLIER *
                MathUtils.lerp(0, 0.25, normalizedProgress) *
                2;
        camera.position.y =
            CAMERA_Y_MULTIPLIER *
            MathUtils.lerp(0, 0.25, normalizedProgress) *
            4;
        camera.updateProjectionMatrix();
        modelProps.rotationX = 0;
    }

    // state 1(tag line visible) to state 2(plate to the right & rotating)
    if (progress > ANIMATION_THRESHOLD_1 && progress < ANIMATION_THRESHOLD_2) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_1) /
            (ANIMATION_THRESHOLD_2 - ANIMATION_THRESHOLD_1);
        tablePosition = [
            0,
            MathUtils.lerp(0, -10, normalizedProgress),
            MathUtils.lerp(0, -10, normalizedProgress),
        ];
        platePosition = [
            MathUtils.lerp(0, 0.7, normalizedProgress),
            MathUtils.lerp(0, 0, normalizedProgress),
            0.7,
        ];
        modelProps.autoRotate = false;
        phoneVisible = false;
        phonePosition = [0, -10, 0];
        modelProps.rotationX = MathUtils.lerp(0, -7, normalizedProgress);
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 0, 10);
        }
    }

    // buffer region between state 2 and state 3
    const buffer = 0.01;
    if (
        progress >= ANIMATION_THRESHOLD_2 - buffer &&
        progress <= ANIMATION_THRESHOLD_3
    ) {
        modelProps.autoRotate = true;
    }

    // state 2state 2(plate to the right & rotating) to state 3(plate to the left & rotating inside phone)
    if (
        progress >= ANIMATION_THRESHOLD_2 &&
        progress <= ANIMATION_THRESHOLD_3
    ) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_2) /
            (ANIMATION_THRESHOLD_3 - ANIMATION_THRESHOLD_2);
        modelProps.autoRotate = true;
        platePosition = [
            MathUtils.lerp(0.7, -0.7, normalizedProgress),
            MathUtils.lerp(0, 0, normalizedProgress),
            0.7,
        ];
        modelProps.rotationX = MathUtils.lerp(-7, 7, normalizedProgress);
        plateScale = MathUtils.lerp(1, 0.7, normalizedProgress);
        phoneVisible = true;
        phonePosition = [-0.8, MathUtils.lerp(-2, 0.35, normalizedProgress), 0];
        // phoneRotation = MathUtils.lerp(0, Math.PI / 15, normalizedProgress);
        modelProps.rotationZ = 20;

        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(
                0,
                MathUtils.lerp(0, 20, normalizedProgress),
                10
            );
        }
    }

    if (progress > ANIMATION_THRESHOLD_3 && progress <= ANIMATION_THRESHOLD_4) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_3) /
            (ANIMATION_THRESHOLD_4 - ANIMATION_THRESHOLD_3);

        plateVisible = true;
        modelProps.autoRotate = true;
        platePosition = [
            MathUtils.lerp(-0.7, 0, normalizedProgress),
            MathUtils.lerp(0, 0.0, normalizedProgress),
            MathUtils.lerp(0.7, 0.95, normalizedProgress),
        ];
        plateScale = MathUtils.lerp(0.7, 0.65, normalizedProgress);
        modelProps.rotationX = MathUtils.lerp(7, 0, normalizedProgress);
        phoneVisible = true;
        phonePosition = [
            MathUtils.lerp(-0.8, 0, normalizedProgress),
            MathUtils.lerp(0.35, 0.6, normalizedProgress),
            MathUtils.lerp(0, -0.3, normalizedProgress),
        ];
        phoneRotation = MathUtils.lerp(0, 0, normalizedProgress);
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 20, 10);
        }
    }

    if (progress > ANIMATION_THRESHOLD_4) {
        plateVisible = true;
        modelProps.autoRotate = true;
        platePosition = [0, 0, 0.95];
        plateScale = 0.65;
        modelProps.rotationX = 0;
        phoneVisible = true;
        phonePosition = [0, 0.6, -0.3];
        phoneRotation = 0;
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 20, 10);
        }
    }

    // if (
    //     progress > ANIMATION_THRESHOLD_3_5 &&
    //     progress <= ANIMATION_THRESHOLD_4
    // ) {
    //     const normalizedProgress =
    //         (progress - ANIMATION_THRESHOLD_3_5) /
    //         (ANIMATION_THRESHOLD_4 - ANIMATION_THRESHOLD_3_5);

    //     plateVisible = false;
    //     modelProps.autoRotate = true;
    //     platePosition = [0, -1, 0.4];
    //     plateScale = 0.45;
    //     phoneVisible = true;
    //     phonePosition = [
    //         MathUtils.lerp(0.0, 3.5, normalizedProgress),
    //         0.9,
    //         -2.5,
    //     ];
    //     phoneRotation = MathUtils.lerp(
    //         0,
    //         (-Math.PI * 20) / 180,
    //         normalizedProgress
    //     );
    //     if (light2Ref.current) {
    //         // reposition the light so it doesn't cause glare on the phone
    //         light2Ref.current.position.set(0, 20, 10);
    //     }
    // }

    // if (progress > ANIMATION_THRESHOLD_4 && progress <= ANIMATION_THRESHOLD_5) {
    //     const normalizedProgress =
    //         (progress - ANIMATION_THRESHOLD_4) /
    //         (ANIMATION_THRESHOLD_4 - ANIMATION_THRESHOLD_5);
    //     plateVisible = false;
    //     modelProps.autoRotate = true;
    //     platePosition = [0, -1, 0.4];
    //     plateScale = 0.45;
    //     phoneVisible = true;
    //     phonePosition = [3.5, 0.9, -2.5];
    //     if (light2Ref.current) {
    //         // reposition the light so it doesn't cause glare on the phone
    //         light2Ref.current.position.set(0, 20, 10);
    //     }
    // }

    useFrame(() => {
        const refs = [groupRef, plateRef, tableRef, phoneRef];

        for (const ref of refs) {
            if (ref.current) {
                if (rotation && progress !== 0 && ref === groupRef) {
                    ref.current.rotation.set(...rotation);
                }
                if (ref === plateRef && platePosition) {
                    ref.current.position.set(...platePosition);
                    ref.current.rotation.set(Math.PI / 2, Math.PI / 2, 0);
                    ref.current.scale.set(plateScale, plateScale, plateScale);
                    ref.current.visible = plateVisible;
                }
                if (ref === tableRef && tablePosition) {
                    ref.current.position.set(...tablePosition);
                }
                if (ref === phoneRef) {
                    ref.current.visible = phoneVisible;
                    if (phonePosition) {
                        ref.current.position.set(...phonePosition);
                    }
                    if (phoneRotation) {
                        ref.current.rotation.set(0, phoneRotation, 0);
                    }
                }
            }
        }

        if (autoRotate && plateRef.current) {
            plateRef.current.rotateY(0.01);
        }
    });

    return (
        <group>
            <group ref={groupRef}>
                <mesh ref={plateRef}>
                    <Model {...modelProps} />
                </mesh>
                <mesh ref={tableRef}>
                    <Table tableGltf={tableGltf} />
                </mesh>
            </group>
            <mesh ref={phoneRef} position={[-50, -50, 0]}>
                <Phone
                    progress={progress}
                    phoneGltf={phoneGltf}
                    foodModelRef={foodModelRef}
                />
            </mesh>
        </group>
    );
};

const RotatingCubePhone = ({
    progress,
    mousePosition,
    cameraPositions,
    light2Ref,
    foodGltf,
    tableGltf,
    phoneGltf,
    foodModelRef,
    setLineY,
}) => {
    const { camera } = useThree();
    const { gl } = useThree();
    useEffect(() => {
        gl.domElement.addEventListener(
            "webglcontextlost",
            function (event) {
                event.preventDefault();
                setTimeout(() => gl.forceContextRestore(), 1);
            },
            false
        );
        gl.domElement.addEventListener(
            "webglcontextrestored",
            function (event) {
                console.log("handle webglcontextrestored");
            },
            false
        );
    }, []);
    const tableRef = useRef();
    const plateRef = useRef();
    const phoneRef = useRef();
    const groupRef = useRef();
    let phoneVisible = false;
    const cameraPositionZ = cameraPositions[0].z;

    const [tiltData, setTiltData] = useState({ alpha: 0, beta: 0, gamma: 0 });

    // Listen to device orientation events
    useEffect(() => {
        const handleDeviceOrientation = (event) => {
            setTiltData({
                alpha: event.alpha || 0,
                beta: event.beta || 0,
                gamma: event.gamma || 0,
            });
        };

        window.addEventListener("deviceorientation", handleDeviceOrientation);

        return () => {
            window.removeEventListener(
                "deviceorientation",
                handleDeviceOrientation
            );
        };
    }, []);

    let platePosition = null;
    let tablePosition = null;
    let tableRotation = null;
    let plateRotation = null;
    let autoRotate = false;
    let phonePosition = null;
    let phoneRotation = null;
    let plateScale = 1;
    let plateVisible = true;

    let rotation = null;
    const modelProps = {
        autoRotate: false,
        foodGltf,
        foodModelRef,
    };

    useEffect(() => {
        const group = groupRef.current;
        if (group && progress === 0) {
            const { x, y } = mousePosition;
            const width = window.innerWidth;
            const height = window.innerHeight;
            const xPercentage = x / width;
            const yPercentage = y / height;
            const xAngle = MathUtils.lerp(
                -Math.PI / 2,
                Math.PI / 2,
                xPercentage
            );
            const yAngle = MathUtils.lerp(
                -Math.PI / 2,
                Math.PI / 2,
                yPercentage
            );

            // Adjust rotation based on device orientation
            const betaAngle = (tiltData.beta || 0) * (Math.PI / 180);
            const gammaAngle = (tiltData.gamma || 0) * (Math.PI / 180);

            gsap.to(groupRef.current.rotation, {
                x: 0.04 * (yAngle + 0.1 * betaAngle),
                y: 0.04 * (xAngle + 0.1 * gammaAngle),
                ease: "power1.out",
                duration: 0.5,
            });
        }
    }, [mousePosition, tiltData]);

    if (progress <= ANIMATION_THRESHOLD_1) {
        const normalizedProgress = progress / ANIMATION_THRESHOLD_1;
        const angleInDegrees = -60 * MathUtils.lerp(0, 1, normalizedProgress);
        const angleInRadians = angleInDegrees * (Math.PI / 180);
        rotation = [angleInRadians, 0, 0];
        platePosition = [0, MathUtils.lerp(0, -0.35, normalizedProgress), 0.7];
        tablePosition = [0, MathUtils.lerp(0, -0.35, normalizedProgress), 0];
        // tableRotation = [MathUtils.lerp(0, 30*Math.PI / 180, normalizedProgress), 0, 0];
        modelProps.autoRotate = false;
        phoneVisible = false;
        phonePosition = [-10, -10, 0];
        camera.position.z =
            cameraPositionZ +
            CAMERA_Z_MULTIPLIER *
                MathUtils.lerp(0, 0.25, normalizedProgress) *
                2;
        camera.position.y =
            CAMERA_Y_MULTIPLIER *
            MathUtils.lerp(0, 0.25, normalizedProgress) *
            5;
        camera.updateProjectionMatrix();
    }

    if (progress > ANIMATION_THRESHOLD_1 && progress < ANIMATION_THRESHOLD_2) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_1) /
            (ANIMATION_THRESHOLD_2 - ANIMATION_THRESHOLD_1);
        tablePosition = [
            0,
            MathUtils.lerp(-0.35, -10, normalizedProgress),
            MathUtils.lerp(0, -10, normalizedProgress),
        ];
        platePosition = [
            0,
            -0.35,
            MathUtils.lerp(0.7, 0.7, normalizedProgress),
        ];
        modelProps.autoRotate = false;
        phoneVisible = false;
        phonePosition = [0, -10, 0];
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 0, 10);
        }
    }

    // buffer region between state 2 and state 3
    const buffer = 0.01;
    if (
        progress >= ANIMATION_THRESHOLD_1 + buffer &&
        progress <= ANIMATION_THRESHOLD_3
    ) {
        modelProps.autoRotate = true;
    }

    if (
        progress >= ANIMATION_THRESHOLD_2 &&
        progress <= ANIMATION_THRESHOLD_3
    ) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_2) /
            (ANIMATION_THRESHOLD_3 - ANIMATION_THRESHOLD_2);
        platePosition = [
            0,
            MathUtils.lerp(-0.35, -0.625, normalizedProgress),
            0.7,
        ];
        plateScale = MathUtils.lerp(1, 0.6, normalizedProgress);
        phonePosition = [0, MathUtils.lerp(-2, -0.15, normalizedProgress), 0.3];
        phoneVisible = true;
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(
                0,
                MathUtils.lerp(0, 20, normalizedProgress),
                10
            );
        }

        plateVisible = true;
    }

    if (progress > ANIMATION_THRESHOLD_3 && progress <= ANIMATION_THRESHOLD_4) {
        const normalizedProgress =
            (progress - ANIMATION_THRESHOLD_3) /
            (ANIMATION_THRESHOLD_4 - ANIMATION_THRESHOLD_3);
        plateVisible = true;
        modelProps.autoRotate = true;
        platePosition = [
            0,
            MathUtils.lerp(-0.625, 0.09, normalizedProgress),
            MathUtils.lerp(0.7, 1.185, normalizedProgress),
        ];
        plateScale = MathUtils.lerp(0.6, 0.7, normalizedProgress);
        plateRotation = [
            MathUtils.lerp(
                Math.PI / 2,
                (1.2 * Math.PI) / 2,
                normalizedProgress
            ),
            Math.PI / 2,
            0,
        ];
        phoneVisible = true;
        phonePosition = [
            0,
            MathUtils.lerp(-0.15, 0.8, normalizedProgress),
            0.3,
        ];
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 20, 10);
        }
    }

    if (progress > ANIMATION_THRESHOLD_4) {
        plateVisible = true;
        modelProps.autoRotate = true;
        platePosition = [0, 0.09, 1.185];
        plateRotation = [(1.2 * Math.PI) / 2, Math.PI / 2, 0];
        plateScale = 0.7;
        phoneVisible = true;
        phonePosition = [0, 0.8, 0.3];
        if (light2Ref.current) {
            // reposition the light so it doesn't cause glare on the phone
            light2Ref.current.position.set(0, 20, 10);
        }
    }

    useFrame(() => {
        const vector = new THREE.Vector3(0, 4, -30);
        const result = vector.project(camera);
        const height = gl.domElement.clientHeight;
        const width = gl.domElement.clientWidth;
        result.x = ((vector.x + 1) * width) / 2;
        result.y = (-(vector.y - 1) * height) / 2;
        result.z = 0;
        setLineY(result.y);

        const refs = [groupRef, plateRef, tableRef, phoneRef];

        for (const ref of refs) {
            if (ref.current) {
                if (rotation && progress !== 0 && ref === groupRef) {
                    ref.current.rotation.set(...rotation);
                }
                if (ref === plateRef && platePosition) {
                    ref.current.position.set(...platePosition);
                    ref.current.rotation.set(Math.PI / 2, Math.PI / 2, 0);
                    if (plateRotation) {
                        ref.current.rotation.set(...plateRotation);
                    }
                    // set the scale of the plate
                    ref.current.scale.set(plateScale, plateScale, plateScale);
                    ref.current.visible = plateVisible;
                }
                if (ref === tableRef && tablePosition) {
                    ref.current.position.set(...tablePosition);
                }
                if (ref === tableRef && tableRotation) {
                    ref.current.rotation.set(...tableRotation);
                }
                if (ref === phoneRef) {
                    ref.current.visible = phoneVisible;
                    if (phonePosition) {
                        ref.current.position.set(...phonePosition);
                    }
                    if (phoneRotation) {
                        ref.current.rotation.set(0, phoneRotation, 0);
                    }
                }
            }
        }

        if (autoRotate && plateRef.current) {
            plateRef.current.rotateY(0.01);
        }
    });

    return (
        <group>
            <group ref={groupRef}>
                <mesh ref={plateRef}>
                    <Model {...modelProps} />
                </mesh>
                <mesh ref={tableRef}>
                    <Table tableGltf={tableGltf} />
                </mesh>
            </group>
            <mesh ref={phoneRef} position={[-50, -50, 0]}>
                <Phone
                    progress={progress}
                    phoneGltf={phoneGltf}
                    foodModelRef={foodModelRef}
                />
            </mesh>
        </group>
    );
};

// Custom hook to track mouse position
export const useMousePosition = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return position;
};

const ParticlesTagLine = ({ progress, dataInput, isTabletOrMobile }) => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const mesh = useRef();
    const groupRef = useRef();
    const geometryRef = useRef();
    const materialRef = useRef();
    const copyGeometryRef = useRef();
    const randomGeometryRef = useRef();
    const lineCopyRef = useRef();
    const planeArea = useRef();
    const sphere = useRef();
    const [yOffset, setYOffset] = useState(dataInput.yStart);
    const [zOffset, setZOffset] = useState(-30);
    const [animationOn, setAnimationOn] = useState(true);
    const [data, setData] = useState(dataInput);
    const [startTime, setStartTime] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        // Set the start time when the component is mounted
        setStartTime(Date.now());

        // Clean up by clearing the interval when the component unmounts
        return () => {
            setElapsedTime(0); // Reset elapsed time when the component unmounts
        };
    }, []);

    useEffect(() => {
        // Update the elapsed time every second
        const interval = setInterval(() => {
            setElapsedTime(Date.now() - startTime);
        }, 1000);

        return () => {
            clearInterval(interval); // Clean up the interval when the component unmounts
        };
    }, [startTime]);

    useEffect(() => {
        // move it up 5 units using scroll progress
        if (progress <= ANIMATION_THRESHOLD_1) {
            const normalizedProgress = progress / ANIMATION_THRESHOLD_1;
            let val = MathUtils.lerp(
                dataInput.yStart,
                dataInput.y1,
                normalizedProgress
            );
            setYOffset(val);
            setZOffset(-30);
            setAnimationOn(true);
        }
    }, [progress]);

    const { camera } = useThree();

    const typo = useLoader(
        FontLoader,
        "https://s3.ap-south-1.amazonaws.com/global.dump/Poppins+ExtraLight_Regular.json"
    );
    const particleTexture = useLoader(
        THREE.TextureLoader,
        "https://res.cloudinary.com/dfvtkoboz/image/upload/v1605013866/particle_a64uzf.png"
    );

    useEffect(() => {
        let thePoints = [];
        const shapes = typo.generateShapes(data.text, data.textSize);
        const geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();
        geometry.center();

        const xMid =
            -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
        const yMid =
            (geometry.boundingBox.max.y - geometry.boundingBox.min.y) / 2.85;

        const holeShapes = [];

        for (let q = 0; q < shapes.length; q++) {
            const shape = shapes[q];

            if (shape.holes && shape.holes.length > 0) {
                for (let j = 0; j < shape.holes.length; j++) {
                    const hole = shape.holes[j];
                    holeShapes.push(hole);
                }
            }
        }
        shapes.push.apply(shapes, holeShapes);

        const colors = [];
        const sizes = [];

        for (let x = 0; x < shapes.length; x++) {
            const shape = shapes[x];

            const amountPoints =
                shape.type === "Path" ? data.amount / 2 : data.amount;

            const points = shape.getSpacedPoints(amountPoints);

            points.forEach((element, z) => {
                const a = new THREE.Vector3(element.x, element.y, 0);
                thePoints.push(a);
                colors.push(1, 1, 1);
                sizes.push(data.particleSize);
            });
        }

        const geoParticles = new THREE.BufferGeometry().setFromPoints(
            thePoints
        );

        geoParticles.translate(xMid, yMid, 0);

        geoParticles.setAttribute(
            "customColor",
            new THREE.Float32BufferAttribute(colors, 3)
        );

        geoParticles.setAttribute(
            "size",
            new THREE.Float32BufferAttribute(sizes, 1)
        );

        const material = new THREE.ShaderMaterial({
            uniforms: {
                color: { value: new THREE.Color(0xffffff) },
                pointTexture: { value: particleTexture },
            },
            vertexShader: document.getElementById("vertexshader").textContent,
            fragmentShader:
                document.getElementById("fragmentshader").textContent,

            blending: THREE.AdditiveBlending,
            transparent: true,
        });

        const particles = new THREE.Points(geoParticles, material);
        particles.position.set(0, 0, -8);
        mesh.current = particles;
        geometry.current = geoParticles;
        material.current = material;

        geometryRef.current = geoParticles;
        materialRef.current = material;
        const copyGeometry = new THREE.BufferGeometry();
        copyGeometry.copy(particles.geometry);
        copyGeometryRef.current = copyGeometry;

        const plane = new THREE.PlaneGeometry(100, 100);
        const planeMaterial = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            visible: false,
        });
        const planeMesh = new THREE.Mesh(plane, planeMaterial);
        planeArea.current = planeMesh;
        planeMesh.position.set(0, 0, -8);
        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32); // Create a small sphere
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphereMesh.visible = true;
        sphere.current = sphereMesh;

        planeMesh.lookAt(camera.position);

        const randomCopy = new THREE.BufferGeometry();
        randomCopy.copy(copyGeometryRef.current);
        randomGeometryRef.current = randomCopy;

        // randomize the position of the particles for the randomCopy
        for (
            let i = 0;
            i < randomCopy.attributes.position.array.length;
            i += 3
        ) {
            randomCopy.attributes.position.array[i] +=
                (Math.random() - 0.5) * 100;
            randomCopy.attributes.position.array[i + 1] +=
                (Math.random() - 0.5) * 100;
            randomCopy.attributes.position.array[i + 2] +=
                (Math.random() - 0.5) * 100;
        }

        const lineCopy = new THREE.BufferGeometry();
        lineCopy.copy(randomGeometryRef.current);
        lineCopyRef.current = lineCopy;

        // make a fraction of the random particles part of a horizontal line
        for (let i = 0; i < lineCopy.attributes.position.array.length; i += 3) {
            // set position randomly on a horizontal line
            lineCopy.attributes.position.array[i] = (Math.random() - 0.5) * 10;
            lineCopy.attributes.position.array[i + 1] = 0;
            lineCopy.attributes.position.array[i + 2] = 0;
        }

        // make the initial position of all the particles set to the random copy
        for (let i = 0; i < particles.geometry.attributes.position.count; i++) {
            const initX = randomCopy.attributes.position.getX(i);
            const initY = randomCopy.attributes.position.getY(i);
            const initZ = randomCopy.attributes.position.getZ(i);

            particles.geometry.attributes.position.setXYZ(
                i,
                initX,
                initY,
                initZ
            );
        }
    }, [data]);

    const raycaster = new THREE.Raycaster();

    useFrame(() => {
        if (isTabletOrMobile) {
            if (
                mesh.current &&
                geometryRef.current &&
                copyGeometryRef.current &&
                materialRef.current &&
                planeArea.current
            ) {
                mesh.current.position.set(
                    data.position.x,
                    data.position.y,
                    data.position.z
                );
                planeArea.current.position.set(
                    data.position.x,
                    data.position.y,
                    data.position.z
                );

                raycaster.setFromCamera(mousePosition, camera);

                const intersects = raycaster.intersectObject(planeArea.current);

                if (intersects.length > 0) {
                    const pos = geometryRef.current.attributes.position;
                    const copy = copyGeometryRef.current.attributes.position;
                    const coulors = geometryRef.current.attributes.customColor;
                    const size = geometryRef.current.attributes.size;

                    for (let i = 0, l = pos.count; i < l; i++) {
                        const initX = copy.getX(i);
                        const initY = copy.getY(i);
                        const initZ = copy.getZ(i);

                        let px = pos.getX(i);
                        let py = pos.getY(i);
                        let pz = pos.getZ(i);
                        if (animationOn) {
                            const colorChange = new THREE.Color();
                            colorChange.setHSL(0.5, 1, 1);
                            coulors.setXYZ(
                                i,
                                colorChange.r,
                                colorChange.g,
                                colorChange.b
                            );
                            coulors.needsUpdate = true;

                            size.array[i] = data.particleSize;
                            size.needsUpdate = true;
                        }

                        const tresh1 = ANIMATION_THRESHOLD_1 - 0.01;
                        const tresh2 = ANIMATION_THRESHOLD_1 + 0.01;
                        if (progress < tresh1) {
                            const normalizedProgress = progress / tresh1;
                            if (randomGeometryRef.current) {
                                // interpolate between the random copy and the copy
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                px = MathUtils.lerp(
                                    randomX,
                                    initX,
                                    normalizedProgress
                                );
                                py = MathUtils.lerp(
                                    randomY,
                                    initY,
                                    normalizedProgress
                                );
                                pz = MathUtils.lerp(
                                    randomZ,
                                    initZ,
                                    normalizedProgress
                                );
                            }
                        } else if (
                            progress > tresh2 &&
                            progress <= ANIMATION_THRESHOLD_3
                        ) {
                            let normalizedProgress =
                                (progress - tresh2) /
                                (ANIMATION_THRESHOLD_2 - tresh2);
                            if (normalizedProgress > 1) {
                                normalizedProgress = 1;
                            }
                            if (lineCopyRef.current) {
                                // interpolate between the text and line copy
                                const lineCopy =
                                    lineCopyRef.current.attributes.position;
                                const lineX = lineCopy.getX(i);
                                const lineY = lineCopy.getY(i);
                                const lineZ = lineCopy.getZ(i);

                                px = MathUtils.lerp(
                                    initX,
                                    lineX,
                                    normalizedProgress
                                );
                                py = MathUtils.lerp(
                                    initY,
                                    lineY,
                                    normalizedProgress
                                );
                                pz = MathUtils.lerp(
                                    initZ,
                                    lineZ,
                                    normalizedProgress
                                );
                            }
                        } else if (
                            progress > ANIMATION_THRESHOLD_3 &&
                            progress <= ANIMATION_THRESHOLD_4
                        ) {
                            const normalizedProgress =
                                (progress - ANIMATION_THRESHOLD_3) /
                                (ANIMATION_THRESHOLD_4 - ANIMATION_THRESHOLD_3);
                            if (randomGeometryRef.current) {
                                // interpolate between the line and random
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                const lineCopy =
                                    lineCopyRef.current.attributes.position;
                                const lineX = lineCopy.getX(i);
                                const lineY = lineCopy.getY(i);
                                const lineZ = lineCopy.getZ(i);

                                px = MathUtils.lerp(
                                    lineX,
                                    randomX,
                                    normalizedProgress
                                );
                                py = MathUtils.lerp(
                                    lineY,
                                    randomY,
                                    normalizedProgress
                                );
                                pz = MathUtils.lerp(
                                    lineZ,
                                    randomZ,
                                    normalizedProgress
                                );
                            }
                        } else if (progress > ANIMATION_THRESHOLD_4) {
                            if (randomGeometryRef.current) {
                                // set to random
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                px = randomX;
                                py = randomY;
                                pz = randomZ;
                            }
                        } else {
                            px += (initX - px) * data.ease;
                            py += (initY - py) * data.ease;
                            pz += (initZ - pz) * data.ease;
                        }

                        pos.setXYZ(i, px, py, pz);
                        pos.needsUpdate = true;
                    }
                }

                const buffer = 0.05;
                if (
                    progress >= ANIMATION_THRESHOLD_1 - buffer &&
                    progress <= ANIMATION_THRESHOLD_3 + buffer
                ) {
                    // Reset rotation to zero when progress is between 0.2 and 0.3
                    groupRef.current.rotation.x = 0;
                    groupRef.current.rotation.y = 0;
                    groupRef.current.rotation.z = 0;
                } else {
                    groupRef.current.rotation.x += 0.0001;
                    groupRef.current.rotation.y += 0.0001;
                    groupRef.current.rotation.z += 0.0001;
                }
            }
        } else {
            if (
                mesh.current &&
                geometryRef.current &&
                copyGeometryRef.current &&
                materialRef.current &&
                planeArea.current
            ) {
                mesh.current.position.set(
                    data.position.x,
                    data.position.y,
                    data.position.z
                );
                planeArea.current.position.set(
                    data.position.x,
                    data.position.y,
                    data.position.z
                );

                raycaster.setFromCamera(mousePosition, camera);

                const intersects = raycaster.intersectObject(planeArea.current);

                if (intersects.length > 0) {
                    const pos = geometryRef.current.attributes.position;
                    const copy = copyGeometryRef.current.attributes.position;
                    const coulors = geometryRef.current.attributes.customColor;
                    const size = geometryRef.current.attributes.size;

                    for (let i = 0, l = pos.count; i < l; i++) {
                        const initX = copy.getX(i);
                        const initY = copy.getY(i);
                        const initZ = copy.getZ(i);

                        let px = pos.getX(i);
                        let py = pos.getY(i);
                        let pz = pos.getZ(i);
                        if (animationOn) {
                            const colorChange = new THREE.Color();
                            colorChange.setHSL(0.5, 1, 1);
                            coulors.setXYZ(
                                i,
                                colorChange.r,
                                colorChange.g,
                                colorChange.b
                            );
                            coulors.needsUpdate = true;

                            size.array[i] = data.particleSize;
                            size.needsUpdate = true;
                        }

                        const tresh1 = ANIMATION_THRESHOLD_1 - 0.01;
                        const tresh2 = ANIMATION_THRESHOLD_1 + 0.01;
                        if (progress < tresh1) {
                            const normalizedProgress = progress / tresh1;
                            if (randomGeometryRef.current) {
                                // interpolate between the random copy and the copy
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                px = MathUtils.lerp(
                                    randomX,
                                    initX,
                                    normalizedProgress
                                );
                                py = MathUtils.lerp(
                                    randomY,
                                    initY,
                                    normalizedProgress
                                );
                                pz = MathUtils.lerp(
                                    randomZ,
                                    initZ,
                                    normalizedProgress
                                );
                            }
                        } else if (
                            progress > tresh2 &&
                            progress <= ANIMATION_THRESHOLD_2
                        ) {
                            const normalizedProgress =
                                (progress - tresh2) /
                                (ANIMATION_THRESHOLD_2 - tresh2);
                            if (randomGeometryRef.current) {
                                // interpolate between the copy and random
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                px = MathUtils.lerp(
                                    initX,
                                    randomX,
                                    normalizedProgress
                                );
                                py = MathUtils.lerp(
                                    initY,
                                    randomY,
                                    normalizedProgress
                                );
                                pz = MathUtils.lerp(
                                    initZ,
                                    randomZ,
                                    normalizedProgress
                                );
                            }
                        } else if (progress > ANIMATION_THRESHOLD_2) {
                            if (randomGeometryRef.current) {
                                // set to random
                                const randomCopy =
                                    randomGeometryRef.current.attributes
                                        .position;
                                const randomX = randomCopy.getX(i);
                                const randomY = randomCopy.getY(i);
                                const randomZ = randomCopy.getZ(i);

                                px = randomX;
                                py = randomY;
                                pz = randomZ;
                            }
                        } else {
                            px += (initX - px) * data.ease;
                            py += (initY - py) * data.ease;
                            pz += (initZ - pz) * data.ease;
                        }

                        pos.setXYZ(i, px, py, pz);
                        pos.needsUpdate = true;
                    }
                }

                const buffer = 0.05;
                if (
                    progress >= ANIMATION_THRESHOLD_1 - buffer &&
                    progress <= ANIMATION_THRESHOLD_1 + buffer
                ) {
                    // Reset rotation to zero when progress is between 0.2 and 0.3
                    groupRef.current.rotation.x = 0;
                    groupRef.current.rotation.y = 0;
                    groupRef.current.rotation.z = 0;
                } else {
                    groupRef.current.rotation.x += 0.0001;
                    groupRef.current.rotation.y += 0.0001;
                    groupRef.current.rotation.z += 0.0001;
                }
            }
        }
    });

    return (
        <group position={[0, yOffset, zOffset]} ref={groupRef}>
            {mesh.current && <primitive object={mesh.current} />}
            {planeArea.current && <primitive object={planeArea.current} />}
        </group>
    );
};

export default AnimatedStates;
