import React, { useState } from 'react';
import InputField from "./InputField"
import "./SignUp.css"
import SubmitButton from "./SubmitButton"
import { useRef } from 'react';
import { signUpRequest } from '../../services/service';

export default function SignUp() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    // const [isValidate, setIsValidate] = useState(false);

        // Create refs
        const firstNameRef = useRef();
        // const lastNameRef = useRef();
        // const cityRef = useRef();
        // const restaurantNameRef = useRef();
        const emailRef = useRef();
        const contactNoRef = useRef();

    const validate = () => {
        const newErrors = {};

        // Add your validation logic here
        newErrors.firstName = (firstNameRef.current.value !== "")? '' : 'First Name is required';
        // newErrors.lastName = (lastNameRef.current.value !== "")? '' : '';
        // newErrors.city = (cityRef.current.value !== "")? '' : 'City is required';
        // newErrors.restaurantName = (restaurantNameRef.current.value !== "")? '' : 'Restaurant Name is required';
        newErrors.email = (emailRef.current.value !== "")? '' : 'Email is required';
        newErrors.contactNo = (contactNoRef.current.value !== "")? '' : 'Contact no. is required';
        setErrors(newErrors);

        // Check if there are any errors
        const hasErrors = Object.values(newErrors).some(error => error !== '');

        return !hasErrors;
    }
    const onClick = async () => {
        if (validate()) {
            const customerInfo = {
                firstName: firstNameRef.current.value,
                lastName: '',
                city: '',
                restaurantName: '',
                email: emailRef.current.value,
                phone: contactNoRef.current.value
            };
            // Call the API
            signUpRequest(customerInfo);
            setIsSubmitted(true);
            return true;
        }
        return false;
    }

    return(
        <div className="signup-container">
            <div className="signup-title">Contact Us</div>
            <div className="signup-content">
                Welcome to <span style={{color: 'orange'}}>Menu Vision</span>! <a href="https://www.menuvision.in" style={{color: 'orange'}}>Explore</a> our <span style={{color: 'orange'}}>3D QR menu</span> and <span style={{color: 'orange'}}>POS system</span> for your restaurant/cafe. Fill out the form below, and we'll reach out soon!
            </div>
            {!isSubmitted && <div className='signup-form'>
                <InputField id="firstName" text="Name" validationFunction={(value) => {
                    if (!value) {
                        return 'First Name is required';
                    }
                    return '';
                }} reff={firstNameRef} externalError={errors.firstName}/>
                {/* <InputField id="lastName" text="Last Name" validationFunction={(value) => {
                    return '';
                }}  reff={lastNameRef} externalError={errors.lastName}/> */}
                {/* <InputField id="city" text="City" validationFunction={(value) => {
                    if (!value) {
                        return 'City is required';
                    }
                    return '';
                }} reff={cityRef} externalError={errors.city}/> */}
                {/* <InputField id="restaurantName" text="Restaurant Name" validationFunction={(value) => {
                    if (!value) {
                        return 'Restaurant Name is required';
                    }
                    return '';
                }}  reff={restaurantNameRef} externalError={errors.restaurantName}/> */}
                <InputField id="email" text="Email" validationFunction={(value) => {
                    if (!value) {
                        return 'Email is required';
                    }
                    if (!value.includes('@')) {
                        return 'Email must include @';
                    }
                    return '';
                }}  reff={emailRef} externalError={errors.email}/> 
                <InputField id="contactNo" text="Contact no." validationFunction={(value) => {
                    if (!value) {
                        return 'Contact no. is required';
                    }
                    if (isNaN(value)) {
                        return 'Contact no. must be a number';
                    }
                    if (value.length !== 10) {
                        return 'Contact no. must be 10 digits';
                    }
                    return '';
                }}  reff={contactNoRef} externalError={errors.contactNo}/>
            </div>}
            {isSubmitted && <div className='thank-you'>Thank you! Expect to hear from us shortly.</div>}
            <SubmitButton onClick={onClick}/>

        </div>
    )
}
function createParticle() {
    const particle = document.createElement('div');
    particle.classList.add('particle');
    particle.style.left = Math.random() * window.innerWidth + 'px';
    particle.style.borderBottomColor = Math.random() < 0.5 ? 'black' : 'orange';
    document.body.appendChild(particle);

    particle.addEventListener('animationend', function() {
        document.body.removeChild(particle);
    });
}

setInterval(createParticle, 400); /* Slower pace */